import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
// import {
//   onResponseFullfilled,
//   onResponseRejected,
// } from "@/api/interceptors.ts"; // Импортируем перехватчики

export type AreaOptions = { root: boolean };
export type UrlRawPath = number | string | unknown[];
export type QueryParams =
  | string
  | string[][]
  | Record<string, string>
  | URLSearchParams
  | undefined;

export interface AreaHttpClient {
  get: <T = unknown, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig | undefined
  ) => Promise<R>;
  post: <T = unknown, R = AxiosResponse<T>, D = unknown>(
    url: string,
    data?: D | undefined,
    config?: AxiosRequestConfig | undefined
  ) => Promise<R>;
  put: <T = unknown, R = AxiosResponse<T>, D = unknown>(
    url: string,
    data?: D | undefined,
    config?: AxiosRequestConfig | undefined
  ) => Promise<R>;
  delete: <T = unknown, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig | undefined
  ) => Promise<R>;
}

const options: AxiosRequestConfig = {
  baseURL: location.origin + "/api/v1",
  timeout: 12000,
};

const http: AxiosInstance = axios.create(options);

function getQuery(params: QueryParams): string {
  const query = new URLSearchParams(params);
  return query ? `?${query}` : "";
}

function resolve(url: UrlRawPath, params?: QueryParams): string {
  const query = params ? getQuery(params) : "";
  return Array.isArray(url) ? `${url.join("/")}${query}` : `${url}${query}`;
}

function area(userId?: number, params?: AreaOptions): AreaHttpClient {
  const baseURL = params?.root ? "/" : `/area/user/${userId}/`;

  return {
    get: <T = unknown, R = AxiosResponse<T>>(
      url: string,
      config?: AxiosRequestConfig
    ): Promise<R> => http.get<T, R>(baseURL + url, config),

    post: <T = unknown, R = AxiosResponse<T>, D = unknown>(
      url: string,
      data?: D,
      config?: AxiosRequestConfig
    ): Promise<R> => http.post<T, R>(baseURL + url, data, config),

    put: <T = unknown, R = AxiosResponse<T>, D = unknown>(
      url: string,
      data?: D,
      config?: AxiosRequestConfig
    ): Promise<R> => http.put<T, R>(baseURL + url, data, config),

    delete: <T = unknown, R = AxiosResponse<T>>(
      url: string,
      config?: AxiosRequestConfig
    ): Promise<R> => http.delete<T, R>(baseURL + url, config),
  };
}

export { area, http, resolve };
export default area;
