import { IBan, ILockPosition } from "@/types/ban";
import { IOperation } from "@/types/operation";
import { IUser } from "@/types/user";
import { ITempPermission } from "@/use/usePermissions";
import { AxiosResponse, CancelToken } from "axios";
import area, { QueryParams, resolve } from "./httpClient";

export type ApiResponse<T = unknown> = Promise<AxiosResponse<T>>;
export type ElixirResponse<T> = { data: T };
export type SpringResponce<T> = { content: T };

// Auth api
export function fetchCurrentUser(): ApiResponse<User> {
  return area(undefined, { root: true }).get<User>("current-user");
}

export function login(credential: UserCredential): ApiResponse<User> {
  const path = credential.isEntrant ? "auth/entrant" : "auth";
  return area(undefined, { root: true }).post<User>(path, credential);
}

export function logout(): ApiResponse {
  return area(undefined, { root: true }).get("logout");
}

export function fetchPermissions(userId: number) {
  return area(userId).get<ITempPermission[]>("types");
}

export function changePassword(
  userId: number,
  passwords: UpdatePasswordData
): ApiResponse {
  return area(userId).put("score", passwords);
}

// TG valid api
export function tgValidation(
  tg_data: TgData,
  score_id: number
): ApiResponse<User> {
  return area(undefined, { root: true }).post<User>("tg-validation", {
    score_id,
    tg_data,
  });
}

export function tgScoreList(tgData: TgData): ApiResponse<User> {
  return area(undefined, { root: true }).post<User>("tg-score-list", tgData);
}

// Users api
export function fetchUsers(): ApiResponse<IUser[]> {
  return area(undefined, { root: true }).get<IUser[]>("area/users");
}

export function touchUser(credential: UserCredential): ApiResponse<User> {
  return area(undefined, { root: true }).post<User>(
    "area/user/touch",
    credential
  );
}

export function fetchRefill(userId: number, total: number): ApiResponse<QrPay> {
  return area(userId).put(resolve(["refill", total]));
}

export function detachUser(userId: number): ApiResponse {
  return area(userId).put("detach");
}

// School api
export function fetchSchool(userId: number): ApiResponse<School> {
  return area(userId).get<School>("school");
}

// Score api
export function fetchScore(userId: number): ApiResponse<UserScore> {
  return area(userId).get<UserScore>("score");
}

// Limit api
export function fetchLimit(userId: number): ApiResponse<ScoreLimit> {
  return area(userId).get<ScoreLimit>("limit");
}

export function updateLimit(userId: number, limit: number): ApiResponse {
  return area(userId).put("limit", { limit });
}

// News api
export function fetchNews(
  userId: number,
  query: QueryParams
): ApiResponse<Post[]> {
  return area(userId).get<Post[]>(resolve("news", query));
}

export function votePost(
  userId: number,
  postId: number,
  variants: number[]
): ApiResponse<Post> {
  return area(userId).post<Post>(resolve(["news", postId]), { variants });
}

// Operations api
export function fetchOperations(
  userId: number,
  query?: QueryParams
): ApiResponse<ElixirResponse<IOperation[]>> {
  return area(userId).get<ElixirResponse<IOperation[]>>(
    resolve("operations", query)
  );
}

// Identifiers api
export function fetchIdentifiers(userId: number): ApiResponse<Identifier[]> {
  return area(userId).get<Identifier[]>("identifiers");
}

export function updateIdentifier(
  userId: number,
  identifierId: number,
  params: { active: boolean }
): ApiResponse {
  return area(userId).put(resolve(["identifier", identifierId]), params);
}

// Services api
export function fetchServices(
  userId: number
): ApiResponse<ElixirResponse<Service[]>> {
  return area(userId).get<ElixirResponse<Service[]>>("services");
}

// Bans api
export function fetchLockPositions(
  userId: number
): ApiResponse<ILockPosition[]> {
  return area(userId).get<ILockPosition[]>("lock-position");
}

export function fetchBans(userId: number): ApiResponse<IBan[]> {
  return area(userId).get<IBan[]>("bans");
}

export function updateBans(userId: number, bans: IBan[]): ApiResponse {
  return area(userId).put("bans", { bans });
}

// Menu api
export function fetchMenu(userId: number): ApiResponse<SchoolMenu> {
  return area(userId).get<SchoolMenu>("menu");
}

export function fetchFoodsets(userId: number): ApiResponse<Foodset[]> {
  return area(userId).get<Foodset[]>("food-set");
}

// Events api
export function fetchEvents(
  userId: number,
  query?: QueryParams
): ApiResponse<SkudEvent[]> {
  return area(userId).get<SkudEvent[]>(resolve("acs-event", query));
}

// Request api
export function fetchRecess(userId: number): ApiResponse<Recess[]> {
  return area(userId).get<Recess[]>("recess");
}

export function fetchRequestAvailable(userId: number): ApiResponse {
  return area(userId).get("request/available");
}

export function fetchRequests(
  userId: number,
  query?: QueryParams
): ApiResponse<SpringResponce<TRequest[]>> {
  return area(userId).get<SpringResponce<TRequest[]>>(
    resolve("request", query)
  );
}

export function fetchRequestsMenu(userId: number) {
  return area(userId).get<SchoolMenu>("request/menu");
}

export function createRequest(
  userId: number,
  request: CreateRequestData
): ApiResponse<TRequest> {
  return area(userId).post<TRequest>("request", request);
}

export function deleteRequest(userId: number, requestId: number): ApiResponse {
  return area(userId).delete(resolve(["request", requestId]));
}

// Tokens api
export function fetchBotToken(userId: number): ApiResponse<TelegramToken> {
  return area(userId).get<TelegramToken>("bot-token");
}

export function fetchLkToken(userId: number): ApiResponse<AuthToken> {
  return area(userId).get<AuthToken>("lk-token");
}

export function fetchEntrantScore(userId: number): ApiResponse<EntrantScore> {
  return area(userId).get<EntrantScore>("entrant-score");
}

// Sessions api
export function fetchSession(userId: number): ApiResponse<LkSession> {
  return area(userId).get("lk-session");
}

export function deleteSession(userId: number): ApiResponse {
  return area(userId).delete("lk-session");
}

// QR-code api
export function fetchIndex(
  userId: number,
  cancelToken: CancelToken
): ApiResponse<number> {
  return area(userId).get<number>("counter-index", { cancelToken });
}

export function fetchQrCodes(
  userId: number,
  timestamp: number,
  query: QueryParams
): ApiResponse<Qr[]> {
  return area(userId)
    .get<Qr[]>(resolve("qr", query))
    .then((response) => ({ ...response, timestamp }));
}

// Sessions api
export function getCurrentStat(userId: number): ApiResponse<StatVoting> {
  return area(userId).get("menu-voting/stat/current");
}

export function getCurrentVoting(userId: number): ApiResponse<Voting> {
  return area(userId).get("menu-voting/current");
}

export function setCurrentVoting(
  userId: number,
  value: number
): ApiResponse<Voting> {
  return area(userId).put(`menu-voting/current/${value}`);
}
