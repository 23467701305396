import { ref } from "vue";

const tg_data = ref<any>(null);

export function getTgInitData(): any {
  if ((window as any).Telegram?.WebApp) {
    const { initData, initDataUnsafe } = (window as any).Telegram.WebApp;
    try {
      if (initData === "") {
        return null;
      } else {
        tg_data.value = initData;
        return tg_data.value;
      }
    } catch (error) {
      console.error("Ошибка: не удалось загрузить данные Telegram.", error);
      return null;
    }
  } else {
    console.error("Telegram WebApp не доступен.");
    return null;
  }
}
