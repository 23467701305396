import { RouteRecordRaw } from "vue-router";

export const settingsRoutes: RouteRecordRaw[] = [
  {
    path: "",
    name: "settins",
    component: () => import("@/views/Settings.vue"),
    meta: { hidden: true },
  },

  {
    path: "change-password",
    name: "change-password",
    component: () => import("@/views/ChangePassword.vue"),
    meta: {
      title: "Смена пароля",
      icon: "LockClosed",
      priority: 1000,
    },
  },

  {
    path: "accounts",
    name: "accounts",
    component: () => import("@/views/Accounts.vue"),
    meta: {
      title: "Управление аккаунтами",
      allowFor: "accounts",
      icon: "Users",
      priority: 900,
    },
  },

  {
    path: "child-auth",
    name: "child-auth",
    component: () => import("@/views/ChildAuth.vue"),
    meta: {
      title: "Авторизация ребенка",
      allowFor: "entrant",
      icon: "Qrcode",
      priority: 900,
    },
  },
];

export const requestRoutes: RouteRecordRaw[] = [
  {
    path: "",
    name: "requests",
    component: () => import("@/features/requests/views/RequestsPage.vue"),
    meta: {
      title: "ОБЕДОМАТ",
      icon: "ShoppingBag",
      priority: 600,
      allowFor: "request",
      group: "Питание",
    },
  },

  {
    path: "create",
    name: "request-create",
    component: () => import("@/views/RequestCreate.vue"),
    meta: {
      hidden: true,
      title: "Новый заказ",
      allowFor: "request",
      group: "Питание",
    },
  },
];

export const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
    meta: {
      title: "Главная",
      tiny_title: "Главная",
      icon: "Home",
      priority: 1000,
      group: "",
    },
  },

  {
    path: "/news",
    name: "newspaper",
    component: () => import("@/features/news/views/NewsPage.vue"),
    meta: {
      title: "Объявления",
      tiny_title: "Объявления",
      icon: "Newspaper",
      priority: 900,
      allowFor: "news",
      group: "Основное",
    },
  },

  {
    path: "/operations",
    name: "operations",
    component: () => import("@/features/operations/views/OperationsPage.vue"),
    meta: {
      title: "Операции",
      tiny_title: "Операции",
      icon: "ChartSquareBar",
      priority: 800,
      allowFor: "score_operation",
      group: "Основное",
    },
  },
  {
    path: "/school-menu",
    name: "school-menu",
    component: () => import("@/views/SchoolMenu.vue"),
    meta: {
      title: "Школьное меню",
      tiny_title: "Меню",
      icon: "Clipboard",
      priority: 500,
      allowFor: "menu",
      group: "Питание",
    },
  },
  {
    path: "/identifiers",
    name: "identifiers",
    component: () => import("@/features/identifiers/views/IdentifiersPage.vue"),
    meta: {
      title: "Идентификаторы",
      tiny_title: "Идентификаторы",
      icon: "CreditCard",
      priority: 700,
      allowFor: "identifiers",
      group: "Основное",
    },
  },

  {
    path: "/requests",
    name: "requests",
    component: () => import("@/views/_layout.vue"),
    children: requestRoutes,
    meta: {
      title: "ОБЕДОМАТ",
      tiny_title: "ОБЕДОМАТ",
      icon: "ShoppingBag",
      priority: 600,
      allowFor: "request",
      group: "Питание",
    },
  },
  {
    path: "/bans",
    name: "bans",
    component: () => import("@/views/Bans.vue"),
    meta: {
      title: "Запреты на меню",
      tiny_title: "Запреты на меню",
      icon: "Ban",
      priority: 400,
      allowFor: "ban",
      group: "Питание",
    },
  },

  {
    path: "/acs-events",
    name: "acs-events",
    component: () => import("@/views/AcsEvents.vue"),
    meta: {
      title: "События СКУД",
      tiny_title: "События СКУД",
      icon: "LockOpen",
      priority: 300,
      allowFor: "acs",
      group: "Прочее",
    },
  },

  {
    path: "/pay",
    name: "pay",
    component: () => import("@/views/Pay.vue"),
    meta: {
      title: "Пополнение счета",
      tiny_title: "Пополнение счета",
      hidden: true,
      allowFor: "pay",
      group: "Прочее",
    },
  },

  // Settings routes
  {
    path: "/settings",
    component: () => import("@/views/_layout.vue"),
    children: settingsRoutes,
    meta: {
      title: "Настройки",
      tiny_title: "Настройки",
      icon: "Adjustments",
      hidden: true,
      // priority: -9999, // Чтобы всегда был в самом низу сортировки
      group: "Прочее",
    },
  },

  {
    path: "/accounts",
    name: "accounts",
    component: () => import("@/views/Accounts.vue"),
    meta: {
      title: "Управление аккаунтами",
      tiny_title: "Управление",
      allowFor: "accounts",
      icon: "Users",
      priority: 900,
      hidden: true,
    },
  },
];
